// 导入axios实例
import httpRequest from '@/request/index'

export default {
    List(params) {
        return httpRequest.post("/author/fuel/list", params)
    },

    Add(params) {
        return httpRequest.post("/author/fuel/add", params)
    },

    Update(params) {
        return httpRequest.post("/author/fuel/update", params)
    },

    Delete(params) {
        return httpRequest.post("/author/fuel/delete", params)
    },
}
