<template>
  <div class="common-layout">
    <el-container>
      <el-header><router-link to="/home">返回图书列表</router-link>  ID:{{ book.id }}, 图书名: {{ book.book_name }}</el-header>
      <el-container>
        <el-aside width="200px">

          <el-button type="primary" @click="dialogNewchatper = true">创建新章</el-button>

          <!-- <el-button type="primary" @click="chapterCreate(book.id)">创建新章</el-button> -->
          <p v-for="v in chapter_list" :key="v.id" @click="focusChapter(v)">{{ v.name }}</p>
        </el-aside>
        <el-main>
          <el-container>
            <el-main>
              <el-row>
                <el-col :span="12">
                  <h3 :style="{ textAlign: 'left' }">第 {{ currentChapter.seq }} 章 <span
                      @click="dialogChangeName = true; changeChapter.name = currentChapter.name ">{{ currentChapter.name
                      }}</span></h3>
                </el-col>
                <el-col :span="12" :style="{ textAlign: 'right' }" @click="showBranch()">
                  <p>分支内容</p>
                </el-col>
              </el-row>
              <el-input v-model="currentChapter.content" :autosize="{ minRows: 4, maxRows: 60 }" type="textarea" />
              <el-row>
                <el-col :span="12">

                </el-col>
                <el-col :span="12" :style="{ textAlign: 'right', paddingTop: '2px' }">
                  <el-button type="primary">去new bing(edge打开)</el-button>
                  <el-button type="primary" @click="genBranch()">去openAI</el-button>
                  <el-button type="success" @click="saveContent()">保存</el-button>
                </el-col>
              </el-row>
            </el-main>
            <el-aside width="200px" :style="{ backgroundColor: 'antiquewhite' }">
              <p class="branches" v-for="v in branch_list" :key="'branch' + v.id"> keyword + {{ v.id }} </p>
            </el-aside>
          </el-container>
        </el-main>
      </el-container>
    </el-container>
  </div>

  <el-dialog v-model="dialogNewchatper" title="创建新章节">
    <el-form>
      <el-form-item label="章节名称">
        <el-input v-model="newChapter.name" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogNewchatper = false">Cancel</el-button>
        <el-button type="primary" @click="chapterCreate">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogChangeName" title="更改章节名">
    <el-form>
      <el-form-item label="章节名称">
        <el-input v-model="changeChapter.name" autocomplete="off" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogChangeName = false">取消</el-button>
        <el-button type="primary" @click="changeChapterSave()">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-drawer v-model="drawer" direction="btt" :modal="false" :show-close="true" :lock-scroll="false" :size="'60%'">
    <el-container>
      <el-aside width="200px" :style="{ backgroundColor: 'antiquewhite' }">
        <el-scrollbar>
          <p class="branches" v-for="v in branch_list" :key="'branch2' + v.id" @click="focusBranch(v)">({{v.platform}}){{ v.content }}
          </p>
        </el-scrollbar>
      </el-aside>

      <el-main>
        <el-input v-model="currentBranch.content" :autosize="{ minRows: 4, maxRows: 60 }" type="textarea" />
      </el-main>
    </el-container>

  </el-drawer>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import AuthorApi from '@/apis/author_api.js'
// import { ElMessage, ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'

// 第一次的时候执行这个函数获取路由参数
const route = useRoute()

// 获取route跳转id，即在  /user/123跳转到/user/456时会触发下面的函数，获取到起点和终点的参数
// 这个方式用的很少，基本不会有这种需求，了解即可 
onBeforeRouteUpdate((to, from) => {
  console.log("from:", from.params.book_id)
  console.log("to:", to.params.book_id)
})
// 版权声明：本文为CSDN博主「流水武qin」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/qq_44749491/article/details/127197378

// import OpenAiApi from '@/apis/openai_api.js'
const drawer = ref(false)
const dialogNewchatper = ref(false)
const dialogChangeName = ref(false)

const book = ref('')
const chapter_list = ref([])
const newChapter = ref({
  name: ''
})
const changeChapter = ref({
  name: ''
})

const currentChapter = ref({ id: 0 })
const branch_list = ref([])
const currentBranch = ref({ id: 0, content: "" })

onMounted(() => {
  getBookInfo(route.params.book_id)
  getChapterList(parseInt(route.params.book_id))
})
function getBookInfo(book_id) {
  AuthorApi.BookGet(book_id).then(
    res => {
      book.value = res.data
      console.log(res)
    },
    err => {
      console.log(err)
    }
  )
}


function getChapterList(book_id) {
  let params = {
    book_id: book_id,
  }

  AuthorApi.ChapterList(params).then(
    res => {
      chapter_list.value = res.data
      console.log(res)
    },
    err => {
      console.log(err)
    }
  )
}

function chapterCreate() {
  let params = {
    book_id: book.value.id,
    name: newChapter.value.name,
  }
  console.log(params)
  AuthorApi.ChapterNew(params).then(
    res => {
      getChapterList(params.book_id)
      dialogNewchatper.value = false
      console.log(res)
      ElMessage({
        type: 'success',
        message: '添加成功',
      })
    },
    err => {
      console.log(err)
    }
  )
}

function focusChapter(chapter) {
  currentChapter.value = chapter
  let params = {
    book_id: book.value.id,
    chapter_id: chapter.id,
    seq: chapter.seq,
  }
  AuthorApi.GetChapterBranch(params).then(
    res => {
      console.log(res)
      branch_list.value = res.data
    },
    err => {
      console.log(err)
    }
  )
}

function showBranch() {
  drawer.value = true
}
function focusBranch(branch) {
  currentBranch.value = branch
  drawer.value = true
}

function genBranch() {
  let params = {
    book_id: book.value.id,
    chapter_id: currentChapter.value.id,
    seq: currentChapter.value.seq,
  }
  AuthorApi.ChapterBranchGen(params).then(
    res => {
      console.log(res)
      if (res.code != 200) {
        ElMessage({
          type: 'error',
          message: res.msg,
        })
        return
      }
      ElMessage({
        type: 'success',
        message: "success",
      })
    },
    err => {
      console.log(err)
    }
  )
}

function saveContent() {
  let params = {
    id: currentChapter.value.id,
    content: currentChapter.value.content
  }

  AuthorApi.ChapterSave(params).then(
    res => {
      console.log(res)
      if (res.code != 200) {
        ElMessage({
          type: 'error',
          message: res.msg,
        })
        return
      }
      ElMessage({
        type: 'success',
        message: "success",
      })
      getChapterList(parseInt(route.params.book_id))
    },
    err => {
      console.log(err)
    }
  )
}


function changeChapterSave() {
  let params = {
    id: currentChapter.value.id,
    name: changeChapter.value.name,
  }
  console.log(params)
  AuthorApi.ChapterSave(params).then(
    res => {
      console.log(res)
      ElMessage({
        type: 'success',
        message: '保存成功',
      })
      getChapterList(parseInt(route.params.book_id))
      dialogChangeName.value = false
      currentChapter.value.name = changeChapter.value.name
    },
    err => {
      console.log(err)
    }
  )
}

// function onSubmit() {
//   let params = {
//   }

//   OpenAiApi.Submit(params).then(
//     res => {
//       console.log(res)
//     },
//     err => {
//       console.log(err)
//     }
//   )
// }




// const open = () => {
//   ElMessageBox.prompt('章节名称', '创建新章', {
//     confirmButtonText: '确定',
//     cancelButtonText: '取消',
//   })
//     .then(({ value }) => {

//     })
//     .catch(() => {
//       ElMessage({
//         type: 'info',
//         message: 'Input canceled',
//       })
//     })
// }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.branches {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-drawer__header {
  padding: "2px" !important;
  margin-bottom: 0;
}

.el-drawer__body {
  padding-top: 0;
}
</style>
