// 导入axios实例
import httpRequest from '@/request/index'

export default {
    BookList(params) {
        return httpRequest.get("/author/book/list", params)
    },

    // 
    BookCreate(params) {
        return httpRequest.post("/author/book/create", params)
    },

    BookGet(book_id) {
        return httpRequest.get("/author/book/" + book_id)
    },

    ChapterNew(params) {
        return httpRequest.post("/author/chapter/new", params)
    },

    ChapterCreate(params) {
        return httpRequest.post("/author/chapter/create", params)
    },

    ChapterList(params) {
        return httpRequest.post("/author/chapter/list", params)
    },

    GetChapterContent(params) {
        return httpRequest.post("/author/chapter/getContent", params)
    },

    GetChapterBranch(params) {
        return httpRequest.post("/author/chapter/branch/list", params)
    },

    ChapterBranchGen(params) {
        return httpRequest.post("/author/chapter/branch/gen", params)
    },

    ChapterSave(params) {
        return httpRequest.post("/author/chapter/save", params)
    },
}
