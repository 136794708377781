<template>
  <el-text class="mx-1" type="info">描述词</el-text>

  <el-row :gutter="20">
    <el-col :span="16" :offset="4">
      <el-button class="mt-4" style="width: 100%" @click="addItemModal = true">Add Item
      </el-button>

      <!-- <el-table :data="tableData" style="width: 100%" stripe="true" :cell-style="cellStyle">
        <el-table-column fixed prop="subject" label="subject" width="150" />
        <el-table-column prop="content" label="Content" :style="{ whiteSpace: 'pre-line' }" />
        <el-table-column prop="mark" label="Mark" width="200" />
        <el-table-column fixed="right" label="Operations" width="120">
          <template #default="scope">
            <el-button link type="primary" size="small" @click.prevent="editRow(scope.$index, scope.row)">Edit</el-button>
            <el-button link type="primary" size="small"
              @click.prevent="deleteRow(scope.$index, scope.row)">Remove</el-button>
          </template>
        </el-table-column>
      </el-table> -->

      <template v-for="item in tableData" :key="item.value">
        <el-row :style="{ marginTop: '5px', border: '1px solid red' }">
          <el-col :span="3">{{ item.subject }}</el-col>
          <el-col :span="15" class="whiteSpacePreLine">{{ item.content }}</el-col>
          <el-col :span="3" class="whiteSpacePreLine">{{ item.mark }}</el-col>
          <el-col :span="3">
            <el-button link type="primary" size="small" @click.prevent="editRow(0, item)">Edit</el-button>
            <el-button link type="primary" size="small" @click.prevent="deleteRow(0, item)">Remove</el-button>
          </el-col>
        </el-row>
      </template>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="24">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </el-col>
  </el-row>

  <el-dialog v-model="addItemModal" title="创建">
    <el-form>
      <el-form-item label="描述对象">
        <el-input v-model="newItem.subject" autocomplete="off" />
      </el-form-item>
      <el-form-item label="词汇">
        <el-input v-model="newItem.content" :autosize="{ minRows: 4, maxRows: 8 }" type="textarea" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="newItem.mark" :autosize="{ minRows: 2, maxRows: 8 }" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addItemModal = false">Cancel</el-button>
        <el-button type="primary" @click="add">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="editItemModal" title="创建">
    <el-form>
      <el-form-item label="描述对象">
        <el-input v-model="editItem.subject" autocomplete="off" />
      </el-form-item>
      <el-form-item label="词汇">
        <el-input v-model="editItem.content" :autosize="{ minRows: 4, maxRows: 8 }" type="textarea" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="editItem.mark" :autosize="{ minRows: 2, maxRows: 8 }" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editItemModal = false">Cancel</el-button>
        <el-button type="primary" @click="update">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.whiteSpacePreLine {
  white-space: pre-line;
  text-align: left;
}
</style>
<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'

import FuleApi from '@/apis/fuel_api.js'

const addItemModal = ref(false)
const editItemModal = ref(false)

const newItem = ref({
  id: 0,
  subject: "",
  content: "",
  mark: "",
})
const editItem = ref({})

// const columns = [
//   {
//     key: 'subject',
//     title: 'subject',
//     dataKey: 'subject',
//     width: 150,
//     align: 'center',
//   },
//   {
//     key: 'content',
//     title: 'content',
//     dataKey: 'content',
//     align: 'center',
//   },
//   {
//     key: 'mark',
//     title: 'mark',
//     dataKey: 'mark',
//     width: 150,
//     align: 'center',
//   },
// ]
// function cellStyle(object) { // { row, column, rowIndex, columnIndex }
//   console.log(object)
//   //  return {color:'red'}
//   if (object.columnIndex == 1) {
//     return {
//       whiteSpace: 'pre-line',
//       wordWrap: 'break-word',
//       wordBreak: 'keep-all',
//       color: "red",
//     }
//   }
// }

const tableData = ref([
  // {
  //   id: 1,
  //   subject: '2016-05-01',
  //   content: '一般化的大学，在校园景观上，也差点意思。像清华大学里，园艺专业的教授亲自上阵在校园里为树木修剪枝丫，打造独具特色的校园文化，而有些大学的园艺师傅好像身上带着反骨，总能让一棵好好生长的树木，变得有点“不正经”。一般化的大学，在校园景观上，也差点意思。像清华大学里，园艺专业的教授亲自上阵在校园里为树木修剪枝丫，打造独具特色的校园文化，而有些大学的园艺师傅好像身上带着反骨，总能让一棵好好生长的树木，变得有点“不正经”。',
  //   mark: "",
  // },
])

onMounted(() => {
  getList()
})



function add() {
  FuleApi.Add(newItem.value).then(
    res => {
      getList()
      addItemModal.value = false
      console.log(res)
      ElMessage({
        type: 'success',
        message: '添加成功',
      })

      newItem.value = {
        id: 0,
        subject: "",
        content: "",
        mark: "",
      }
    },
    err => {
      console.log(err)
    }
  )
}

function update() {
  FuleApi.Update(editItem.value).then(
    res => {
      getList()
      editItemModal.value = false
      console.log(res)
      ElMessage({
        type: 'success',
        message: '更新成功',
      })

      editItem.value = {}
    },
    err => {
      console.log(err)
    }
  )
}

function getList() {
  FuleApi.List({}).then(
    res => {
      tableData.value = res.data
      console.log(res)
    },
    err => {
      console.log(err)
    }
  )
}

const deleteRow = (index, row) => {
  ElMessageBox.confirm('删除此记录?')
    .then(() => {
      FuleApi.Delete({ "id": row.id }).then(
        () => {
          ElMessage({
            type: 'success',
            message: '成功',
          })
          tableData.value.splice(index, 1)
        },
        err => {
          console.log(err)
        }
      )
    })
    .catch(() => {
      // catch error
    })
}

const editRow = (index, row) => {
  console.log(index)
  editItem.value = row
  editItemModal.value = true
}


</script>

