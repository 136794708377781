
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import ComHome from '@/components/ComHome.vue'
import ComAbout from '@/components/ComAbout.vue'
import HelloWorld from './components/HelloWorld.vue'
import BookGen from '@/components/BookGen.vue'
import FuelIndex from '@/components/FuelIndex.vue'
import CharacterCreation from '@/components/CharacterCreation.vue'
import UserLogin from '@/components/UserLogin.vue'

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由.
const routes = [
    { path: '/', component: HelloWorld },
    { path: '/home', component: ComHome },
    { path: '/book-gen/:book_id', component: BookGen },
    { path: '/about', component: ComAbout },
    { path: '/fuel', component: FuelIndex },
    { path: '/character', component: CharacterCreation },
    { path: '/user-login', component: UserLogin },
]



// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})


const app = createApp(App)
app.use(ElementPlus).use(router)
app.mount('#app')

