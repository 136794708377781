<template>
  <div >
    <img src="/images/character.jpg"/>
    <!-- https://www.renrendoc.com/paper/178725721.html -->
  </div>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
