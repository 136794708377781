<template>
  <form>
    <div class="mb-3">
      <label for="username" class="form-label">用户名</label>
      <input type="text" class="form-control" id="username" placeholder="用户名" v-model="loginForm.username">
    </div>
    <div class="mb-3">
      <label for="passowrd" class="form-label">密码</label>
      <input type="password" class="form-control" id="passowrd" placeholder="密码" autocomplete="on" v-model="loginForm.password">
    </div>
    <button type="button" class="btn btn-success" @click="UserLogin">登陆</button>
  </form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import AuthApi from '@/apis/auth_api.js'

// import { ElMessage, ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'

// 版权声明：本文为CSDN博主「流水武qin」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/qq_44749491/article/details/127197378

const loginForm = ref({
  username: '',
  password: '',
})

onMounted(() => {
})

function UserLogin() {
  AuthApi.Login(loginForm.value).then(
    res => {
      console.log(res)
      // this.$cookie.set('token', token, 1);
      localStorage.setItem('token', res.data);

      if (res.code != 0) {
        ElMessage({
          type: 'error',
          message: res.msg,
        })
       return 
      }
      window.location.href = '/'
    },
    err => {
      console.log(err)
    }
  )
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style ></style>
