import axios from 'axios'


//声明一个基础接口变量
let baseURL;
 
//配置开发环境
if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://127.0.0.1:8098/';
}
 
// 配置生产环境
if (process.env.NODE_ENV === 'production') {
  baseURL = 'http://audit.himyou.com/';
}

//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
// const header = {
//     Accept: 'application/json;charset=UTF-8',
//   }
// ————————————————
// 版权声明：本文为CSDN博主「你开心的样子我好喜欢」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/qq_38682174/article/details/125416084


// 创建一个 axios 实例
const service = axios.create({
	baseURL: baseURL, // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
	headers: {
		// 设置后端需要的传参类型
		'Content-Type': 'application/json',
		'token': 'your token',
		'X-Requested-With': 'XMLHttpRequest',
	},
})

// 添加请求拦截器
service.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		config.headers.Token=localStorage.getItem("token")
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		console.log(response)
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		// 这个状态码是和后端约定的
		// const code = dataAxios.reset
        // console.log(code)
		if (dataAxios.code == 1) {
			window.location.href = '/#/user-login'
			return 
		}
		return dataAxios
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		console.log(error)
		return Promise.reject(error)
	}
)

export default service

