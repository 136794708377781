<template>
  <el-container :style="{ height: '100%' }">
    <el-main :style="{ flexGrow: '1' }">
      <el-form>
        <el-form-item>
          <label>new book</label>
          <el-input v-model="book_name" type="input" />
        </el-form-item>
        <el-form-item class="right-btn">
          <div :style="{ flexGrow: '1' }"></div>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>

      <template v-for="v in book_list_data" :key="v.id">
        <a :href="'/#/book-gen/' + v.id">{{ v.book_name }}</a><br />
      </template>
      
    </el-main>
  </el-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import AuthorApi from '@/apis/author_api.js'

const book_name = ref('')
const book_list_data = ref([])

onMounted(() => {
  getBookList()
})

function getBookList() {
  let params = {
  }

  AuthorApi.BookList(params).then(
    res => {
      console.log(res)
      book_list_data.value = res.data
    },
    err => {
      console.log(err)
    }
  )
}

function onSubmit() {
  let params = {
    book_name: book_name.value,
  }

  AuthorApi.BookCreate(params).then(
    res => {
      console.log(res)
      getBookList()
    },
    err => {
      console.log(err)
    }
  )

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-left {
  justify-content: flex-start;
  text-align: left;
}

.content-right {
  justify-content: flex-end;
  text-align: right;
}

.right-btn .el-form-item__content {
  justify-content: flex-end;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
</style>
